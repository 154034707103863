// @flow

import React, { memo, useState, useCallback } from 'react';
import { ButtonDeprecated, Text, Flex } from '@graphite/uneon';
import logger from 'libs/logger';

import SettingsGrid from './SettingsGrid';
import { ReactComponent as Grid } from './icons/grid.svg';
import SettingsFont from './SettingsFont';
import { ReactComponent as Fonts } from './icons/fonts.svg';
import SettingsColor from './SettingsColor';
import { ReactComponent as Colors } from './icons/colors.svg';
// import SettingsComponent from './SettingsComponent';
// import Components from './icons/components.svg';
import SettingsEffect from './SettingsEffect';
import { ReactComponent as Effects } from './icons/effects.svg';

import Item from './DesignItem';
import type { TTabInfo } from './DesignItem';

type TTabs = $ReadOnlyArray<TTabInfo>;

const tabs: TTabs = [
	{
		id: 'grid',
		title: 'Grid',
		desc: 'Spacing and Columns',
		Icon: Grid,
		Panel: SettingsGrid,
	},
	{
		id: 'fonts',
		title: 'Fonts',
		desc: 'Pair and Styles',
		Icon: Fonts,
		Panel: SettingsFont,
	},
	{
		id: 'colors',
		title: 'Colors',
		desc: 'Palette and Themes',
		Icon: Colors,
		Panel: SettingsColor,
	},
	{
		id: 'effects',
		title: 'Variables',
		desc: 'Radius and Borders',
		Icon: Effects,
		Panel: SettingsEffect,
	},
	// {
	// 	id: 'components',
	// 	title: 'Components',
	// 	desc: 'Component presets',
	// 	Icon: Components,
	// 	Panel: SettingsComponent,
	// },
];

const designTitleStyle = {
	marginBottom: '18px',
	cursor: 'default',
};

const panelTitleStyle = {
	marginBottom: '18px',
};

const columnStyle = {
	flexDirection: 'column',
	marginBottom: '18px',
};

const buttonStyle = {
	justifyContent: 'flex-start',
	width: '120px',
	marginBottom: '24px',
};

const iconsBack = [
	{
		name: 'arrow-top',
		iconSize: 18,
		rotation: 270,
		size: 'xxsm',
		sx: {
			marginRight: '9px',
		},
	},
];

function Design() {
	const [tab, setTab] = useState(null);

	React.useEffect(() => {
		logger.info('openDesignPanel');
	}, []);

	const goBack = useCallback(() => setTab(null), [setTab]);

	return tab ? (
		tabs.map(({ id, Panel, title }) => {
			if (tab === id) {
				return (
					<React.Fragment key={id}>
						<ButtonDeprecated
							as="div"
							label="Design"
							variant="flat"
							size="sm"
							colors="secondary"
							icons={iconsBack}
							sx={buttonStyle}
							onClick={goBack}
						/>
						<Text
							as="h3"
							variant="title3"
							color="text.primaryalt"
							sx={panelTitleStyle}
						>
							{title}
						</Text>
						<Panel />
					</React.Fragment>
				);
			}
		})
	) : (
		<>
			<Text as="h2" variant="title3" color="text.primaryalt" sx={designTitleStyle}>
				Design
			</Text>
			<Flex sx={columnStyle}>
				{tabs.map(tab => (
					<Item key={tab.id} info={tab} click={setTab} />
				))}
			</Flex>
		</>
	);
}

export default memo<{}>(Design);
